import React from 'react';
import { useState } from "react";
import ReactDOM from 'react-dom/client';
import './index.scss';
import './keyframes.css';

import { LayoutComponent } from "export";

function App() {
  return (
    <LayoutComponent.Main />
  );
}

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App/>);
