import { LayoutComponent } from "export";

export const Footer = () => {
  const footer = (
    <LayoutComponent.View>
      <section id="page-footer">
        <div className="footer-col">
        </div>
        <div className="footer-col">
        </div>
      </section>
    </LayoutComponent.View>
  );

  return footer;
}
