import { useState, useEffect } from "react";
import { LayoutComponent, Components } from "export";

export const Main = () => {
  const main = (
    <LayoutComponent.View>
      <LayoutComponent.Cart />
      <LayoutComponent.Nav />
      <>
        <Components.ConstructionText />
        <LayoutComponent.FrontStage />
        <LayoutComponent.SecondSection />
        <LayoutComponent.ThirdSection />
      </>
      <LayoutComponent.Footer />
    </LayoutComponent.View>
  );

  return main;
}
