import { useState } from "react";

export const Icon = props => {
  const icon = (
    <span
      className={`${props.clicked ? "clicked" : ""} icon`}
    >
      <Svg icon={props.icon} />
    </span>
  );

  return icon;
}
export const ClickableIcon = props => {
  const icon = (
    <span
      className={`${props.clicked ? "clicked" : ""} icon clickable`}
      onClick={() => { props.onClick() }}
    >
      <Svg icon={props.icon} />
    </span>
  );

  return icon;
}
const Svg = props => {
  const assets = {
    "Menu":
    <>
      <path id="menu-top" d="M3 6L21 6" />
      <path id="menu-middle" d="M3 12L21 12" />
      <path id="menu-bottom" d="M3 18L21 18" />
    </>,
    "Cart":
    <>
      <path d="M3 3L5.32 3C5.7298 2.9996 6.09837 3.24928 6.25 3.63L10 13L8.72 15.55C8.56322 15.8611 8.57965 16.2314 8.76338 16.5274C8.9471 16.8234 9.27163 17.0024 9.62 17L19 17" />
      <path d="M7.2 6L20.8 6L21 6L18.2 13L10 13" />
      <path d="M10.95 20.5L11.05 20.5" />
      <path d="M16.95 20.5L17.05 20.5" />
    </>,
    "Instagran":
    <>
      <path d="M15.24 12C15.24 10.2 13.8 8.76 12 8.76C10.2 8.76 8.76 10.2 8.76 12C8.76 13.8 10.2 15.24 12 15.24C13.8 15.24 15.24 13.8 15.24 12ZM16.92 12C16.92 14.76 14.76 16.92 12 16.92C9.24 16.92 7.08 14.76 7.08 12C7.08 9.24 9.24 7.08 12 7.08C14.76 7.08 16.92 9.24 16.92 12ZM18.24 6.84C18.24 7.56 17.76 8.04 17.04 8.04C16.32 8.04 15.84 7.56 15.84 6.84C15.84 6.12 16.32 5.64 17.04 5.64C17.76 5.64 18.24 6.24 18.24 6.84ZM12 4.08C10.56 4.08 7.56 3.96 6.36 4.44C5.52 4.8 4.8 5.52 4.56 6.36C4.08 7.56 4.2 10.56 4.2 12C4.2 13.44 4.08 16.44 4.56 17.64C4.8 18.48 5.52 19.2 6.36 19.44C7.56 19.92 10.68 19.8 12 19.8C13.32 19.8 16.44 19.92 17.64 19.44C18.48 19.08 19.08 18.48 19.44 17.64C19.92 16.32 19.8 13.32 19.8 12C19.8 10.68 19.92 7.56 19.44 6.36C19.2 5.52 18.48 4.8 17.64 4.56C16.44 3.96 13.44 4.08 12 4.08ZM21.6 12L21.6 15.96C21.6 17.4 21.12 18.84 20.04 20.04C18.96 21.12 17.52 21.6 15.96 21.6L8.04 21.6C6.6 21.6 5.16 21.12 3.96 20.04C3 18.96 2.4 17.52 2.4 15.96L2.4 12L2.4 8.04C2.4 6.48 3 5.04 3.96 3.96C5.16 3 6.6 2.4 8.04 2.4L15.96 2.4C17.4 2.4 18.84 2.88 20.04 3.96C21 5.04 21.6 6.48 21.6 8.04C21.6 8.04 21.6 12 21.6 12Z" />
    </>,
    "Facebook":
    <>
      <path d="M3.1125 2L20.9 2C21.5125 2 22 2.4875 22 3.1L22 20.9C22 21.5 21.5125 22 20.9 22L15.8 22L15.8 14.25L18.4 14.25L18.7875 11.2375L15.8 11.2375L15.8 9.3125C15.8 8.4375 16.05 7.8375 17.3 7.8375L18.9 7.8375L18.9 5.1375C18.625 5.1 17.675 5.025 16.575 5.025C14.2625 5.025 12.6875 6.425 12.6875 9.0125L12.6875 11.2375L10.075 11.2375L10.075 14.25L12.6875 14.25L12.6875 22L3.1125 22C2.5 22 2 21.5 2 20.9L2 3.1C2 2.4875 2.5 2 3.1125 2Z" />
    </>,
    "Pinterest":
    <>
      <path d="M12.2405 2.3811C6.95008 2.3811 4.18464 5.74771 4.18464 9.47504C4.18464 11.2786 5.14653 13.0821 6.7096 14.044C7.19055 14.2845 7.07031 14.044 7.43102 12.6012C7.43102 12.4809 7.43102 12.3607 7.31078 12.2405C5.14653 9.59527 6.95008 4.42511 12 4.42511C19.3344 4.42511 17.8916 14.525 13.3226 14.525C12.3607 14.6452 11.5191 13.9238 11.5191 12.9619L11.5191 12.4809C12 11.1583 12.3607 9.95598 12.4809 8.63338C12.4809 6.10842 8.75362 6.46913 8.75362 9.83575C8.75362 10.4369 8.87386 11.0381 9.11433 11.5191C9.11433 11.5191 7.91197 16.4487 7.67149 17.2904C7.43102 18.7332 7.55126 20.1761 7.79173 21.4987C7.67149 21.6189 7.79173 21.6189 7.79173 21.6189L7.91197 21.6189C8.75362 20.4165 9.47504 19.2142 9.95598 17.8916C10.0762 17.2904 10.6774 15.1261 10.6774 15.1261C11.2786 15.9678 12.3607 16.4487 13.4428 16.4487C17.1702 16.4487 19.8154 13.2024 19.8154 9.23457C19.8154 5.26677 16.4487 2.3811 12.2405 2.3811Z" />
    </>

  }

  const returnIcon = () => {
    for(let asset in assets) {
      if(props.icon == asset) {
        return assets[asset];
      }
    }
  }

  const [icon, setIcon] = useState(returnIcon());

  const svg = (
    <svg viewBox="0 0 24 24">
      {icon}
    </svg>
  );

  return svg;
}
