import { useState, useEffect } from "react";
import { LayoutComponent, ClickableIcon } from "export";

export const Nav = props => {
  const [retracted, setRetracted] = useState(false);

  const updateRetracted = () => {
    setRetracted(retracted => !retracted);
  }

  const layout = (
    <LayoutComponent.View>
      <nav
        className={`${retracted ? "retracted" : ""} hover`}
      >
        <ClickableIcon icon="Menu" clicked={retracted} onClick={() => { updateRetracted() }} />
      </nav>
    </LayoutComponent.View>
  );

  return layout;
}
