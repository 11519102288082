import { LayoutComponent } from "export";

export const FrontStage = () => {
  const frontStage = (
    <section id="front-stage">
      
    </section>
  );

  return frontStage;
}
