import { useState, useEffect } from "react";

const LayoutComponent = props => {
  const layout = (
    <>
      {props.children}
    </>
  );

  return layout
}

export { LayoutComponent as View };

export { Nav } from "./Nav";
export { Cart } from "./Cart";
export { Main } from "./Main";
export { FrontStage } from "./FrontStage";
export { SecondSection } from "./SecondSection";
export { ThirdSection } from "./ThirdSection";
export { Footer } from "./Footer";
