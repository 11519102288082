import { useState, useEffect } from "react";
import { LayoutComponent, ClickableIcon } from "export";

export const Cart = () => {
  const [displayed, setDisplayed] = useState(false);

  const updateDisplayed = () => {
    setDisplayed(displayed => !displayed);
  }

  const cart = (
    <LayoutComponent.View>
      <div id="cart" className={`${displayed ? "displayed" : ""} hover`}>
        <ClickableIcon icon="Cart" clicked={displayed} onClick={() => { updateDisplayed() }}/>
      </div>
    </LayoutComponent.View>
  );

  return cart;
}
